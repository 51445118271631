<template lang="pug">
.locations-view
  .locations-view__block-one
    .locations-view-title {{ $t('layout.header.contacts.locations.title1') }}
    .locations-view-text {{ $t('layout.header.contacts.locations.text1') }}
  .locations-view__block-two
    .locations-view-title {{ $t('layout.header.contacts.locations.title2') }}
    .locations-view-text {{ $t('layout.header.contacts.locations.text2') }}
</template>

<script>
export default {
  name: 'LocationsViewComponent'
}
</script>

<style lang="sass" scoped>
.locations-view
  line-height: 20px
  &-title
    font-weight: 700
    margin-bottom: 8px
  &__block-one
    margin-bottom: 20px
</style>
